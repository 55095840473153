<header class="popup_header__2QTxC clearfix">
  <h3 class="popup_title__3q6Xh"><i class="la la la-unlock"></i> <span>Reset Password</span></h3>
  <div class="popup_headerRight__c4FcP">
    <span [mat-dialog-close] class="popup_close__mJx2A color-primary--hover js-toggle-close">
      <i class="la la-close"></i>
    </span>
  </div>
</header>
<div mat-dialog-content class="bdy-mat-dialog-content">
  <div class="popup_body__1wtsy wil-scroll-bar ps">
    <div class="wil-scroll-container">
      <div>
        <div>
          <div>
            <p>Reset key has been sent to your email.</p>
            <form [formGroup]="resetPassForm" (ngSubmit)="onSubmit()">
              <mat-form-field>
                <mat-label>Reset Key</mat-label>
                <input matInput formControlName="reset_key" placeholder="Reset Key" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>New Password</mat-label>
                <input matInput formControlName="password" placeholder="" type="password" />
              </mat-form-field>
              <p class="form-error" *ngIf="resetPassForm.get('password').hasError('pattern')">Password must be at
                least 8 characters, must contain one lowercase, one uppercase and one number</p>

              <mat-form-field>
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="con_password" placeholder="" type="password" />
              </mat-form-field>

              <div *ngIf="showError">
                <p *ngFor="let errorMessage of errorMessage" class="errorMessage">{{ errorMessage }}</p>
              </div>

              <button mat-raised-button color="primary"
                class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round" type="submit"
                [disabled]="!resetPassForm.valid">
                Submit
              </button>
            </form>
            <p *ngIf="passwordUpdated" style="margin-top: 20px; color: green;">Password successfully updated. You can
              now <a (click)="loginClicked()" class="td-underline">Login</a></p>
          </div>
          <!-- <div class="mt-15"><span>Don't have an account?</span>
            <a (click)="registerClicked()" class="wil-float-right td-underline">Register</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>