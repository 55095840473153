<header class="popup_header__2QTxC listing-gallery-popup-header video-popup-header clearfix">
  <div class="popup_headerRight__c4FcP">
    <span [mat-dialog-close] class="popup_close__mJx2A color-primary--hover js-toggle-close">
      <i class="la la-close"></i>
    </span>
  </div>
</header>
<div mat-dialog-content class="bdy-mat-dialog-content">
  <div class="iframe-wrapper">
    <iframe class="mfp-iframe" [src]="videoUrl" allowfullscreen="" frameborder="0"></iframe>
  </div>
</div>