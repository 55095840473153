<div *ngIf="helperService.currentUserInfo?.verified === 0" class="not-activated">
  Your account is not verified. Please check your email to verify your account. <a (click)="resendEmail()">Resend email</a>
</div>

<app-header></app-header>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<app-footer></app-footer>

<div *ngIf="showLoadingSpinner" class="loading-spinner-wrapper">
    <div class="loading-spinner loadingio-spinner-spin-pilfpgewbk">
        <div class="ldio-v4reu5effxf">
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
        </div>
    </div>
</div>
