<header class="popup_header__2QTxC clearfix">
  <h3 class="popup_title__3q6Xh"><i class="la la la-unlock"></i> <span>Candidate Portfolio</span></h3>
  <div class="popup_headerRight__c4FcP">
    <span [mat-dialog-close] class="popup_close__mJx2A color-primary--hover js-toggle-close">
      <i class="la la-close"></i>
    </span>
  </div>
</header>
<div mat-dialog-content class="bdy-mat-dialog-content">
  <div class="popup_body__1wtsy wil-scroll-bar ps">
    <div class="wil-scroll-container">
      <div>
        <div>
          <div>
            <form [formGroup]="portfolioForm" (ngSubmit)="onSubmit()">
              <mat-form-field>
                <mat-label>Title <span class="mat-form-field-required-marker">*</span></mat-label>
                <input matInput formControlName="title" placeholder="Title" />
              </mat-form-field>

              <section class="section-mat-form-field section-field-file section-field-preview-img">
                <mat-label>Image</mat-label>
                <input accept="image/*" type="file" formControlName="image" #inputPortfolioImage
                  (change)="onPortfolioImageChange($event)" />
                <span *ngIf="portfolioImageSrc" [ngStyle]="{'background-image': 'url('+portfolioImageSrc+')'}"></span>
                <a class="wil-btn wil-btn--primary wil-btn--round wil-btn--xxs"
                  (click)="inputPortfolioImage.click()">Upload</a>
              </section>
              <mat-progress-bar *ngIf="progressImage > 0" mode="determinate" [value]="progressImage" class="mb-15">
              </mat-progress-bar>
              <p class="form-error" *ngIf="!formCustomvalidation.image.validated">
                {{formCustomvalidation.image.message}}</p>

              <mat-form-field>
                <mat-label>Youtube URL</mat-label>
                <input matInput formControlName="youtube_url" placeholder="Youtube URL" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Site URL</mat-label>
                <input matInput formControlName="site_url" placeholder="Site URL" />
              </mat-form-field>

              <mat-form-field class="invisible">
                <input matInput formControlName="id">
              </mat-form-field>

              <button mat-raised-button class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round"
                type="submit" [disabled]="!portfolioForm.valid">
                {{data.edit ? 'Update' : 'Add'}} Portfolio
              </button>

              <p *ngIf="showError" class="errorMessage">{{ errorMessage }}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
