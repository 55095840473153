<div class="wil-content wilcity-archive">
  <section class="wil-section">
    <div class="container">
      <h2>Unsubscribe Job Alert</h2>

      <ng-container *ngIf="unsubscribed != null">
        <p *ngIf="unsubscribed">Unsubscribed successfully!</p>
        <p *ngIf="!unsubscribed">Request is not valid</p>
      </ng-container>
      <p></p>
    </div>
  </section>
</div>