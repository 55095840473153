<header class="popup_header__2QTxC clearfix">
  <h3 class="popup_title__3q6Xh"><span>Job Alert</span></h3>
  <div class="popup_headerRight__c4FcP">
    <span [mat-dialog-close] class="popup_close__mJx2A color-primary--hover js-toggle-close">
      <i class="la la-close"></i>
    </span>
  </div>
</header>
<div mat-dialog-content class="bdy-mat-dialog-content">
  <div class="popup_body__1wtsy wil-scroll-bar ps">
    <div class="wil-scroll-container">
      <div>
        <div>
          <div>
            <form [formGroup]="jobAlertForm" (ngSubmit)="onSubmit()">

              <div class="row">
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Keyword <span class="mat-form-field-required-marker">*</span></mat-label>
                    <input matInput formControlName="keyword" placeholder="keyword" />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Sector <span class="mat-form-field-required-marker">*</span></mat-label>
                    <mat-select formControlName="sector" placeholder="Select Sector" #singleSelect
                      (openedChange)="onSelectOpen($event, jobSectorFilterInput)">
                      <input class="selectSearch" #jobSectorFilterInput matInput placeholder="Sector"
                        autocomplete="off" />
                      <mat-option *ngFor="let jobSector of filteredJobSectors(jobSectorFilterInput.value)"
                        [value]="jobSector.id" [innerHTML]="jobSector.title">

                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Job Type <span class="mat-form-field-required-marker">*</span></mat-label>
                    <mat-select formControlName="type">
                      <mat-option *ngFor="let jobType of jobService.jobTypes" [value]="jobType.value"
                        [innerHTML]="jobType.viewValue">
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field>
                    <mat-label>Salary</mat-label>
                    <input matInput formControlName="salary" placeholder="salary" />
                  </mat-form-field>
                </div>
              </div>

              <button mat-raised-button class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round"
                type="submit" [disabled]="!jobAlertForm.valid">
                Submit
              </button>

              <p *ngIf="showError" class="errorMessage">{{ errorMessage }}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>