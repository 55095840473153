<div
  class="container"
  (mouseenter)="showDelete = true"
  (mouseleave)="showDelete = false"
  dropFile
  (fileDropped)="onFileDropped($event)"
>
  <div class="overlay progress-overlay" *ngIf="progress > 0">
    <mat-progress-spinner color="primary" mode="determinate" [value]="progress"> </mat-progress-spinner>
  </div>
  <div *ngIf="file && showDelete" class="overlay delete-overlay" (click)="deleteImage()">
    <mat-icon>highlight_off</mat-icon>
  </div>
  <img *ngIf="file" [src]="fileStr" />
  <label *ngIf="!file">
    <mat-icon color="primary">add_circle</mat-icon>
    <div *ngIf="text">{{ text }}</div>
    <input type="file" accept="image/*" (change)="onImageChange($event)" />
    <p class="form-error" *ngIf="!validation.validated">
      {{ validation.message }}
    </p>
  </label>
</div>
