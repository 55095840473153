<header class="popup_header__2QTxC clearfix">
  <h3 class="popup_title__3q6Xh"><span>Event RSVP</span></h3>
  <div class="popup_headerRight__c4FcP">
    <span [mat-dialog-close] class="popup_close__mJx2A color-primary--hover js-toggle-close">
      <i class="la la-close"></i>
    </span>
  </div>
</header>
<div mat-dialog-content class="bdy-mat-dialog-content">
  <div class="popup_body__1wtsy wil-scroll-bar ps">
    <div class="wil-scroll-container">
      <div>
        <div>
          <div>
            <form [formGroup]="rsvpForm" (ngSubmit)="onSubmit()">
              <mat-form-field>
                <mat-label>Title</mat-label>
                <input required matInput formControlName="title" placeholder="Title" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Capacity</mat-label>
                <input matInput formControlName="capacity" placeholder="Capacity" />
                <mat-hint>Leave blank for unlimited</mat-hint>
              </mat-form-field>

              <mat-form-field>
                <input matInput formControlName="start_sale" autocomplete="off" placeholder="Start sale"
                  [owlDateTimeTrigger]="start_sale" [owlDateTime]="start_sale">
                <owl-date-time #start_sale></owl-date-time>
                <mat-hint>If you leave blank, RSVP will be available immediately.</mat-hint>
              </mat-form-field>

              <mat-form-field>
                <input matInput formControlName="end_sale" autocomplete="off" placeholder="End sale"
                  [owlDateTimeTrigger]="end_sale" [owlDateTime]="end_sale">
                <owl-date-time #end_sale></owl-date-time>
                <mat-hint>If you leave blank, RSVP will be available until the event begins.</mat-hint>
              </mat-form-field>

              <mat-form-field class="invisible">
                <input matInput formControlName="id">
              </mat-form-field>

              <button mat-raised-button class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round mt-20"
                type="submit" [disabled]="!rsvpForm.valid">
                {{data.edit ? 'Update' : 'Add'}} RSVP
              </button>

              <p *ngIf="showError" class="errorMessage">{{ errorMessage }}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>