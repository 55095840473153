<footer class="footer_module__1uDav">
    <div class="footer_widgets__3FIuV">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-6">
                    <section id="media_image-2" class="widget widget_media_image"><img width="300" height="186"
                            src="assets/img/nirectory-footer-logo.png"
                            class="image wp-image-13357 footer-logo attachment-medium size-medium lazyloaded" alt=""
                            style="max-width: 100%; height: auto;" sizes="(max-width: 300px) 100vw, 300px"
                            data-ll-status="loaded"></section>
                    <section id="nav_menu-1" class="widget widget_nav_menu">
                        <div class="menu-footer-menu-container">
                            <ul id="menu-footer-menu" class="menu">
                                <li id="menu-item-13355"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13355"><a
                                        [routerLink]="['/contact-us']">Contact Us</a></li>
                                <li id="menu-item-13740"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13740"><a
                                        [routerLink]="['/promote']">Advertise With Us</a>
                                </li>
                                <li id="menu-item-13740"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13740"><a
                                        [routerLink]="['/report-issue']">Report An Issue</a>
                                </li>
                                <li id="menu-item-13742"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13742"><a
                                        [routerLink]="['/about-us']">About Us</a></li>
                                <li id="menu-item-13353"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13353"><a
                                        [routerLink]="['/terms--conditions']">Terms &amp;
                                        Conditions</a></li>
                                <li id="menu-item-13354"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13354"><a
                                        [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                                <li id="menu-item-14707"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-14707"><a
                                        [routerLink]="['/faqs']">FAQs</a></li>
                            </ul>
                        </div>
                    </section>
                    <section id="wilcity_mailchimp-1" class="widget widget_wilcity_mailchimp">
                        <div class="content-box_body__3tSRB">
                            <div class="widget-subsc">
                                <div class="widget-subsc__text"></div>
                                <div class="alert_module__Q4QZx alert_danger__2ajVf error hidden">
                                    <div class="alert_icon__1bDKL"><i class="la la-frown-o"></i></div>
                                    <div class="alert_content__1ntU3 err-msg"></div>
                                </div>
                                <form [formGroup]="mailchimpForm" (ngSubmit)="onSubmitSubscription()"
                                    class="wilcity-mailchimp-form widget-subsc__form">
                                    <div class="form-item">
                                        <input required formControlName="email" type="email" placeholder="eNewsletter">
                                    </div>
                                    <div class="form-submit">
                                        <button type="submit" aria-label="Subscribe"><i
                                                class="la la-envelope"></i></button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </section>
                </div>
                <div class="col-md-6 col-lg-6">
                </div>
            </div>
        </div>
    </div>
    <div class="footer_textWrap__Xc_Ht ">
        <div class="container">
            <div class="div-footer-copyright">
                <div class="footer_text__1FkcM">© 2022 Nirectory. All Rights Reserved </div>
                <div class="social-icon_module__HOrwr">
                    <a class="social-icon_item__3SLnb"
                        href="https://www.facebook.com/BlackDirectoryLtd/?modal=admin_todo_tour" rel="noopener"
                        target="_blank"><i class="fa fa-facebook"></i>
                    </a> <a class="social-icon_item__3SLnb" href="https://twitter.com/black_directory" rel="noopener"
                        target="_blank"><i class="fa fa-twitter"></i>
                    </a> <a class="social-icon_item__3SLnb s-icon-instagram"
                        href="https://www.instagram.com/black.directory/" rel="noopener" target="_blank"><i
                            class="fa fa-instagram"></i>
                    </a> <a class="social-icon_item__3SLnb s-icon-linkedin"
                        href="https://www.linkedin.com/company/black-directory" rel="noopener" target="_blank"><i
                            class="fa fa-linkedin"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="wil-scroll-top " [ngClass]="{'active': pageYoffset > 1000}">
    <a title="To top" (click)="scrollToTop()">
        <i class="la la-angle-up"></i>
    </a>
</div>