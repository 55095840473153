<header class="popup_header__2QTxC clearfix">
  <h3 class="popup_title__3q6Xh"><span>Leave a review</span></h3>
  <div class="popup_headerRight__c4FcP">
    <span [mat-dialog-close] class="popup_close__mJx2A color-primary--hover js-toggle-close">
      <i class="la la-close"></i>
    </span>
  </div>
</header>
<div mat-dialog-content class="bdy-mat-dialog-content">
  <div class="popup_body__1wtsy wil-scroll-bar ps">
    <div class="wil-scroll-container">
      <div>
        <div>
          <div>
            <form [formGroup]="listingReviewForm" (ngSubmit)="onSubmit()">

              <div class="form-field-slider-wrapper">
                <mat-label>How Would You Rate This Business?</mat-label>
                <mat-slider color="primary" formControlName="rating" thumbLabel tickInterval="1" value="10" step="1"
                  min="1" max="10" aria-label="units"></mat-slider>
              </div>

              <mat-form-field>
                <mat-label>Review Title</mat-label>
                <input matInput formControlName="title" placeholder="Review Title" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Content</mat-label>
                <textarea matInput formControlName="description" rows="5"></textarea>
              </mat-form-field>

              <p *ngIf="showError" class="errorMessage">{{ errorMessage }}</p>

              <button mat-raised-button class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round"
                type="submit" [disabled]="!listingReviewForm.valid">
                Submit
              </button>


            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>