<header class="popup_header__2QTxC clearfix">
  <h3 class="popup_title__3q6Xh"><span>{{data.title}}</span></h3>
</header>

<div mat-dialog-content class="bdy-mat-dialog-content information-dialog">
  <div class="popup_body__1wtsy wil-scroll-bar ps">
    <div class="wil-scroll-container">
      <div>
        <p>{{data.message}}</p>

        <button mat-raised-button mat-dialog-close color="primary"
          class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
