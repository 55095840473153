<header class="popup_header__2QTxC clearfix">
  <h3 class="popup_title__3q6Xh"><span>Date</span></h3>
  <div class="popup_headerRight__c4FcP">
    <span [mat-dialog-close] class="popup_close__mJx2A color-primary--hover js-toggle-close">
      <i class="la la-close"></i>
    </span>
  </div>
</header>
<div mat-dialog-content class="bdy-mat-dialog-content">
  <div class="popup_body__1wtsy wil-scroll-bar ps">
    <div class="wil-scroll-container">
      <div>Choose date range for starting time of events</div>
      <div>
        <mat-form-field>
          <mat-label>From</mat-label>
          <input [(ngModel)]="chosenStartTime" matInput [matDatepicker]="picker_start" (focus)="picker_start.open()">
          <mat-datepicker-toggle matSuffix [for]="picker_start"></mat-datepicker-toggle>
          <mat-datepicker #picker_start></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>To</mat-label>
          <input [(ngModel)]="chosenEndTime" matInput [matDatepicker]="picker_end" (focus)="picker_end.open()">
          <mat-datepicker-toggle matSuffix [for]="picker_end"></mat-datepicker-toggle>
          <mat-datepicker #picker_end></mat-datepicker>
        </mat-form-field>
      </div>

      <div data-v-50937791="" class="filter_footer__104sU clearfix">
        <div data-v-50937791="" class="wil-float-left">
          <a (click)="onClear()" class="wil-btn wil-btn--primary wil-btn--round wil-btn--sm">
            <span class="">Clear</span>
          </a>
        </div>
        <div data-v-50937791="" class="wil-float-right">
          <a (click)="onApply()" class="wil-btn wil-btn--secondary wil-btn--round wil-btn--sm">
            <span class="">Apply</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>