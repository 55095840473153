<header class="popup_header__2QTxC clearfix">
  <h3 class="popup_title__3q6Xh"><i class="la la la-unlock"></i> <span>Forgot Password</span></h3>
  <div class="popup_headerRight__c4FcP">
    <span [mat-dialog-close] class="popup_close__mJx2A color-primary--hover js-toggle-close">
      <i class="la la-close"></i>
    </span>
  </div>
</header>
<div mat-dialog-content class="bdy-mat-dialog-content">
  <div class="popup_body__1wtsy wil-scroll-bar ps">
    <div class="wil-scroll-container">
      <div>
        <div>
          <div>
            <form [formGroup]="forgotPassForm" (ngSubmit)="onSubmit()">
              <mat-form-field>
                <mat-label>Email Address</mat-label>
                <input matInput formControlName="email" placeholder="Email" />
              </mat-form-field>

              <div *ngIf="showError">
                <p *ngFor="let errorMessage of errorMessage" class="errorMessage">{{ errorMessage }}</p>
              </div>

              <button mat-raised-button color="primary"
                class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round" type="submit"
                [disabled]="!forgotPassForm.valid">
                Submit
              </button>
            </form>
          </div>
          <!-- <div class="mt-15"><span>Don't have an account?</span>
            <a (click)="registerClicked()" class="wil-float-right td-underline">Register</a>
          </div>
          <div>
            <div class="divider-text_module__3vqhE divider-text_center__299NM mt-15 mb-15">
              <span class="divider-text_text__2OtOt">Or Connect With</span>
            </div>
            <a href="#" target="_self"
              class="mt-10 wil-btn wil-btn--facebook wil-btn--block wil-btn--md wil-btn--round">
              <i class="fa fa-facebook"></i> <span class="">Login With Facebook</span>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>