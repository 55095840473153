<header class="popup_header__2QTxC clearfix">
  <h3 class="popup_title__3q6Xh"><i class="la la la-unlock"></i> <span>Candidate Experience</span></h3>
  <div class="popup_headerRight__c4FcP">
    <span [mat-dialog-close] class="popup_close__mJx2A color-primary--hover js-toggle-close">
      <i class="la la-close"></i>
    </span>
  </div>
</header>
<div mat-dialog-content class="bdy-mat-dialog-content">
  <div class="popup_body__1wtsy wil-scroll-bar ps">
    <div class="wil-scroll-container">
      <div>
        <div>
          <div>
            <form [formGroup]="experienceForm" (ngSubmit)="onSubmit()">
              <mat-form-field>
                <mat-label>Title <span class="mat-form-field-required-marker">*</span></mat-label>
                <input matInput formControlName="title" placeholder="Title" />
              </mat-form-field>

              <div class="row">
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>From Date <span class="mat-form-field-required-marker">*</span></mat-label>
                    <input matInput formControlName="start_date" [matDatepicker]="exp_start_date"
                      (focus)="exp_start_date.open()">
                    <mat-datepicker-toggle matSuffix [for]="exp_start_date"></mat-datepicker-toggle>
                    <mat-datepicker #exp_start_date></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field>
                    <mat-label>To Date</mat-label>
                    <input matInput formControlName="end_date" [matDatepicker]="exp_end_date"
                      (focus)="exp_end_date.open()">
                    <mat-datepicker-toggle matSuffix [for]="exp_end_date"></mat-datepicker-toggle>
                    <mat-datepicker #exp_end_date></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <section class="mt-10">
                    <mat-checkbox class="" color='primary' formControlName="present">
                      Present
                    </mat-checkbox>
                  </section>
                </div>
              </div>

              <mat-form-field>
                <mat-label>Company <span class="mat-form-field-required-marker">*</span></mat-label>
                <input matInput formControlName="company" placeholder="Company" />
              </mat-form-field>

              <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description" rows="5"></textarea>
              </mat-form-field>

              <mat-form-field class="invisible">
                <input matInput formControlName="id">
              </mat-form-field>

              <button mat-raised-button class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round"
                type="submit" [disabled]="!experienceForm.valid">
                {{data.edit ? 'Update' : 'Add'}} Experience
              </button>

              <p *ngIf="showError" class="errorMessage">{{ errorMessage }}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
