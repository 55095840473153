<div data-margin-top="0" class="dashboard__sidebar js-sticky"
  style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;">
  <div class="theiaStickySidebar"
    style="padding-top: 0px; padding-bottom: 0px; position: static; transform: none; top: 0px; left: 0px;">
    <div class="utility-box-1_module__MYXpX wil-text-center dashboard-profile-img">
      <div class="utility-box-1_avatar__DB9c_ rounded-circle" [ngStyle]="{'background-image': 'url('+profileImage+')'}">
        <img [src]="profileImage" alt="">
      </div>
      <div class="utility-box-1_body__8qd9j">
        <div *ngIf="currentUser.display_name" class="utility-box-1_group__2ZPA2">
          <h3 class="utility-box-1_title__1I925">{{currentUser.display_name}}</h3>
        </div>
        <div class="utility-box-1_description__2VDJ6">
          <div class="utility-box-1_description__2VDJ6"></div>
        </div>
      </div>
    </div>
    <div class="dashboard-nav_module__3c0Pb list-none mt-20 mb-30 pos-r">
      <ul>

        <!-- news menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">News</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['news-add']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Add News</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['news-all']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Manage News</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['news-categories']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Categories</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['top-news']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="fa fa-arrow-circle-o-up"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Top News</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <!-- job menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Job</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews">
                  <span>
                    <a [routerLink]="['new-job']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Post a New Job</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages">
                  <span>
                    <a [routerLink]="['manage-jobs']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i>
                      </span>
                      <span class="dashboard-nav_text__x-_IZ">Manage Jobs</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['job-sectors']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Sectors</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <!-- ----------------------- Vendor Dashboard Starts ----------------------- -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-shopping-cart"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Products</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews">
                  <span>
                    <a [routerLink]="['products']" routerLinkActive="dashboard-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="fa fa-fw fa-th-list"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Product List</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages">
                  <span>
                    <a [routerLink]="['product-new']" routerLinkActive="dashboard-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="fa fa-fw fa-plus"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">New Product</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews">
                  <span>
                    <a [routerLink]="['product-categories']" routerLinkActive="dashboard-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="fa fa-fw fa-th-list"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Categories</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews">
                  <span>
                    <a [routerLink]="['product-options']" routerLinkActive="dashboard-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="fa fa-fw fa-th-list"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Options</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews">
                  <span>
                    <a [routerLink]="['all-orders']" routerLinkActive="dashboard-link-active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="fa fa-fw fa-address-card-o"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Orders</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews">
                  <span>
                    <a [routerLink]="['withdraw-requests']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="fa fa-fw fa-money"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Withdraw Requests</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>
            </mat-expansion-panel>
          </mat-accordion>
        </li>
        <!-- ------------------------ Vendor Dashboard Ends ------------------------ -->

        <!-- listing menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Listing</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-listings">
                  <span><a [routerLink]="['listings']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">All Listings</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['listing-categories']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Categories</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['listing-claims']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Claims</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <!-- event menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Events</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-events">
                  <span><a [routerLink]="['events']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-calendar-o"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">All Events</span>
                      <span class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['event-categories']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Categories</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['event-tags']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Tags</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['event-organizers']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Organizers</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <!-- mobiles menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Mobiles</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['mobiles-add']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Add Mobiles</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['mobiles-all']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Manage Mobiles</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['mobiles-providers']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Providers</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <!-- deals menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Deals</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['deals-add']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Add Deal</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['deals-all']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Manage Deals</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['dealers']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-list"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Dealers</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <!-- pages menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Pages</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['page-add']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Add Page</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['page-all']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Manage Pages</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['manage-faqs']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Manage FAQs</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <!-- forums menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Forums</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['forums/all']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">All Forums</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['forum-categories']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Forum Categories</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['topics/all']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">All Topics</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['replies/all']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">All Posts</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <!-- travels menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Travels</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['new-travel']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Add Travel</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['all-travels']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Manage Travels</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <!-- finance menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Finance</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['new-finance']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-plus"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Add Finance</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['all-finance']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Manage Finance</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>

        <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
            <a [routerLink]="['hero-slider']" routerLinkActive="dashboard-link-active"
              class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
              <span class="dashboard-nav_text__x-_IZ">Home Hero Slider</span> <span
                class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
        </li>

        <!-- user menu -->
        <li>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>
                    <a class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">Users</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a>
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ul class="sidebar-menu-submenus">
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-reviews"><span>
                    <a [routerLink]="['users']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="la la-home"></i></span>
                      <span class="dashboard-nav_text__x-_IZ">All Users</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['users-request']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Users Requests</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
                <li class="dashboard-nav_item__2798B wilcity-dashboard-route-messages"><span>
                    <a [routerLink]="['users-deactivated']" routerLinkActive="dashboard-link-active"
                      class="dashboard-nav_link__2BmK9 text-ellipsis color-primary--hover"><span
                        class="dashboard-nav_icon__2gZV4"><i class="jobsearch-icon jobsearch-briefcase-1"></i> </span>
                      <span class="dashboard-nav_text__x-_IZ">Deactivated Users</span> <span
                        class="dashboard-nav_number__5N1Ch color-primary"></span></a></span>
                </li>
              </ul>

            </mat-expansion-panel>
          </mat-accordion>
        </li>
      </ul>
    </div>
  </div>
</div>