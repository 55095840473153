<div class="jobsearch-job-subheader" [style.background-image]="coverImage ? 'url(' + coverImage + ')' : ''">
  <span class="jobsearch-banner-transparent"></span>
</div>

<div *ngIf="userType == 'candidate'" class="candidate-details">
  <div class="jobsearch-main-content">
    <div class="jobsearch-main-section">
      <div class="jobsearch-plugin-default-container">
        <div class="jobsearch-row">
          <aside class="jobsearch-column-4 jobsearch-typo-wrap">
            <div class="jobsearch_side_box jobsearch_box_candidate_info">
              <div class="jobsearch_candidate_info">

                <figure><img [src]="profileImage" alt=""></figure>
                <h2>{{currentUser.display_name || currentUser.username}}</h2>
                <p *ngIf="userMeta.job_title">{{userMeta.job_title}}</p>
                <p [innerHTML]="currentUser.sector"></p>
                <p *ngIf="userMeta.salary_amount && userMeta.salary_type">Salary:
                  £{{userMeta.salary_amount | number}} / {{userMeta.salary_type}}</p>
                <small>Member Since, {{currentUser.created_at | date:'longDate'}}</small>
                <ul>
                  <li *ngIf="userMeta.facebook_link">
                    <a [href]="userMeta.facebook_link" data-original-title="facebook" target="_blank"
                      class="jobsearch-icon jobsearch-facebook-logo"></a>
                  </li>
                  <li *ngIf="userMeta.linkedin_link">
                    <a [href]="userMeta.linkedin_link" target="_blank" data-original-title="linkedin"
                      class="jobsearch-icon jobsearch-linkedin-button"></a>
                  </li>
                </ul>
                <a (click)="updateSaveCandidateStatus()"
                  class="jobsearch-candidate-default-btn jobsearch-add-resume-to-list"><i
                    class="jobsearch-icon jobsearch-add-list"></i> {{candidateSaved ? 'Saved' : 'Save Candidate'}}</a>

                <div *ngIf="currentUser.phone" class="jobsearch-whatsapp-msgcon jobsearch_whatsap_view_1">
                  <a [href]="'https://wa.me/' + currentUser.phone +'?text=Hi'" target="_blank">
                    <i class="fa fa-whatsapp"></i>
                    <small>WhatsApp</small>
                  </a>
                </div>
              </div>
            </div>

            <div *ngIf="currentUser.latitude && currentUser.longitude" class="jobsearch_side_box jobsearch_box_map">
              <div class="jobsearch-map">
                <div id="googleMap" style="width:100%; height:300px;"></div>
              </div>
            </div>
            <div class="jobsearch_side_box jobsearch_box_contact_form">
              <div class="jobsearch-wdg-box-title">
                <h2>Contact Form</h2>
              </div>
              <form [formGroup]="contactCandidateForm" (ngSubmit)="onSubmitContactForm(candidateContactForm)"
                #candidateContactForm="ngForm">

                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name" placeholder="Name" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Email</mat-label>
                  <input required matInput formControlName="email" placeholder="Email" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Phone</mat-label>
                  <input matInput formControlName="phone" placeholder="Phone" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Message</mat-label>
                  <textarea matInput formControlName="message" rows="5"></textarea>
                </mat-form-field>

                <button class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round" type="submit"
                  [disabled]="!contactCandidateForm.valid || loading">
                  Send
                </button>

                <p *ngIf="showCandidateError" class="errorMessage">{{ errorMessageCandidate }}</p>

                <div class="loading-spinner" *ngIf="loading">
                  <i class="fa fa-spinner fa-spin"></i>
                </div>

                <p *ngIf="message" class="message">{{message}}</p>
              </form>
            </div>
          </aside>

          <div class="jobsearch-column-8 jobsearch-typo-wrap">
            <div class="container-wrapper container-wrapper-view1">
              <div class="jobsearch-candidate-editor">
                <div class="jobsearch-content-title">
                  <h2>About {{currentUser.display_name || currentUser.username}}</h2>
                </div>
                <div class="jobsearch-jobdetail-services">
                  <ul class="jobsearch-row">
                    <li *ngIf="academics" class="jobsearch-column-4"> <i
                        class="jobsearch-icon jobsearch-mortarboard"></i>
                      <div class="jobsearch-services-text">Academic Level
                        <small *ngFor="let item of academics">{{item}}</small>
                      </div>
                    </li>
                    <li *ngIf="userMeta.age" class="jobsearch-column-4"> <i class="jobsearch-icon jobsearch-user-2"></i>
                      <div class="jobsearch-services-text">Age <small>{{userMeta.age}}</small>
                      </div>
                    </li>
                    <li *ngIf="userMeta.job_industry" class="jobsearch-column-4"> <i
                        class="jobsearch-icon jobsearch-network"></i>
                      <div class="jobsearch-services-text">Industry
                        <small>{{userMeta.job_industry}}</small>
                      </div>
                    </li>
                  </ul>
                </div>
                <div *ngIf="currentUser.is_business && currentUser.description">
                  <div class="jobsearch-content-title">
                    <h2>Description</h2>
                  </div>
                  <div class="jobsearch-description">
                    {{currentUser.description}}
                  </div>
                </div>
              </div>

              <div *ngIf="userProfile.educations">
                <div class="jobsearch-candidate-title">
                  <h2><i class="jobsearch-icon jobsearch-mortarboard"></i> Education </h2>
                </div>
                <div class="jobsearch-candidate-timeline">
                  <ul class="jobsearch-row">
                    <li *ngFor="let item of userProfile.educations" class="jobsearch-column-12">
                      <small>{{item.year}}</small>
                      <div class="jobsearch-candidate-timeline-text">
                        <span>{{item.institute}}</span>
                        <h2>{{item.title}}</h2>
                        <p *ngIf="item.description">{{item.description}}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="userProfile.experiences">
                <div class="jobsearch-candidate-title">
                  <h2>
                    <i class="jobsearch-icon jobsearch-social-media"></i> Experience
                  </h2>
                </div>
                <div class="jobsearch-candidate-timeline">
                  <ul class="jobsearch-row">
                    <li *ngFor="let item of userProfile.experiences" class="jobsearch-column-12">
                      <small>{{item.start_date | date: 'yyyy'}} -
                        {{item.present ? 'Present' : item.end_date | date:
                        'yyyy'}}</small>
                      <div class="jobsearch-candidate-timeline-text">
                        <span>{{item.company}}</span>
                        <h2>{{item.title}}</h2>
                        <p *ngIf="item.description">{{item.description}}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="userProfile.portfolios">
                <div class="jobsearch-candidate-title">
                  <h2><i class="jobsearch-icon jobsearch-briefcase"></i> Portfolio </h2>
                </div>
                <div class="jobsearch-gallery candidate_portfolio">
                  <ul class="jobsearch-row grid" style="position: relative; height: 202px;">
                    <li *ngFor="let item of userProfile.portfolios" class="grid-item jobsearch-column-3">
                      <figure>
                        <span class="grid-item-thumb"><small
                            [ngStyle]="{'background-image': 'url('+ item.image  +')'}"></small></span>
                        <figcaption>
                          <div class="img-icons">
                            <a *ngIf="item.youtube_url" target="_blank" [href]="item.youtube_url"
                              class="fancybox-video"><i class="fa fa-play"></i></a>
                            <a *ngIf="item.site_url" [href]="item.site_url" target="_blank"><i
                                class="fa fa-chain"></i></a>
                          </div>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div> <!-- Main Section -->
  </div>
</div>

<div *ngIf="userType == 'employer' || userType == 'admin'" class="employer-details single-employer">
  <div class="jobsearch-main-content">
    <div class="jobsearch-main-section">
      <div class="jobsearch-plugin-default-container">
        <div class="jobsearch-row">

          <div class="jobsearch-column-12 jobsearch-typo-wrap">
            <figure class="jobsearch-jobdetail-list">
              <span class="jobsearch-jobdetail-listthumb">
                <img [src]="profileImage" alt="">
              </span>
              <figcaption>
                <div class="jobsearch-detrating-con">
                  <div class="jobsearch-rating-info">{{averageRating || ''}}</div>
                  <div class="jobsearch-rating">
                    <small class="jobsearch-rating-box" [ngStyle]="{'width': averageRating/5*100 + '%'}"></small>
                  </div>
                </div>
                <h2>{{currentUser.display_name || currentUser.username}}</h2>
                <ul class="jobsearch-jobdetail-options">
                  <li *ngIf="currentUser.address">
                    <i class="fa fa-map-marker"></i> {{currentUser.address}} <a
                      [href]="'https://www.google.com/maps/search/' + currentUser.latitude + ',' + currentUser.longitude"
                      target="_blank" class="jobsearch-jobdetail-view">View
                      on Map</a>
                  </li>
                  <li>
                    <i class="jobsearch-icon jobsearch-mail"></i> <a href="mailto:{{currentUser.email}}">Email:
                      {{currentUser.email}}</a>
                  </li>
                  <li *ngIf="currentUser.phone">
                    <i class="jobsearch-icon jobsearch-technology"></i> Telephone: {{currentUser.phone}}
                  </li>
                </ul>

                <a (click)="scrollIntoView(addReviewForm)"
                  class="jobsearch-go-to-review-form jobsearch-employerdetail-btn"><i
                    class="jobsearch-icon jobsearch-add"></i> Add a review </a>
                <a (click)="followEmployer()" class="jobsearch-employerdetail-btn jobsearch-othercand-role-btn"><i
                    class="fa fa-user-plus"></i>
                  {{followingEmployer ? 'Following' : 'Follow'}}</a>

                <ul *ngIf="hasSocialLinks" class="jobsearch-jobdetail-media jobsearch-add-space">
                  <li><span>Social Links:</span></li>

                  <li *ngIf="socialLinks.instagram_link">
                    <a href="{{ socialLinks.instagram_link }}" target="_blank">
                      <img src="/assets/social/instagram.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.facebook_link">
                    <a href="{{ socialLinks.facebook_link }}" target="_blank">
                      <img src="/assets/social/facebook.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.twitter_link">
                    <a href="{{ socialLinks.twitter_link }}" target="_blank">
                      <img src="/assets/social/twitter.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.linkedin_link">
                    <a href="{{ socialLinks.linkedin_link }}" target="_blank">
                      <img src="/assets/social/linkedin.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.tiktok_link">
                    <a href="{{ socialLinks.tiktok_link }}" target="_blank">
                      <img src="/assets/social/tiktok.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.pinterest_link">
                    <a href="{{ socialLinks.pinterest_link }}" target="_blank">
                      <img src="/assets/social/pinterest.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.youtube_link">
                    <a href="{{ socialLinks.youtube_link }}" target="_blank">
                      <img src="/assets/social/youtube.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.spotify_link">
                    <a href="{{ socialLinks.spotify_link }}" target="_blank">
                      <img src="/assets/social/spotify.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.apple_music_link">
                    <a href="{{ socialLinks.apple_music_link }}" target="_blank">
                      <img src="/assets/social/apple_music.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.tidal_link">
                    <a href="{{ socialLinks.tidal_link }}" target="_blank">
                      <img src="/assets/social/tidal.png" class="social-icon">
                    </a>
                  </li>

                  <li *ngIf="socialLinks.soundcloud_link">
                    <a href="{{ socialLinks.soundcloud_link }}" target="_blank">
                      <img src="/assets/social/soundcloud.png" class="social-icon">
                    </a>
                  </li>
                </ul>
              </figcaption>
            </figure>
          </div>
          <!-- Job Detail List -->

          <!-- Job Detail Content -->
          <div class="jobsearch-column-8 jobsearch-typo-wrap">
            <div class="jobsearch-jobdetail-content jobsearch-employerdetail-content">
              <div class="jobsearch-content-title">
                <h2>Overview</h2>
              </div>
              <div class="jobsearch-jobdetail-services">
                <ul class="jobsearch-row">
                  <li class="jobsearch-column-4" *ngIf="currentUser.sector">
                    <i class="jobsearch-icon jobsearch-folder"></i>
                    <div class="jobsearch-services-text">Sectors<small [innerHTML]="currentUser.sector"></small></div>
                  </li>
                  <li class="jobsearch-column-4">
                    <i class="jobsearch-icon jobsearch-briefcase"></i>
                    <div class="jobsearch-services-text">Posted Jobs <small>{{activeJobsCount}}</small>
                    </div>
                  </li>
                  <li class="jobsearch-column-4">
                    <i class="jobsearch-icon jobsearch-view"></i>
                    <div class="jobsearch-services-text">Viewed <small>{{ currentUser.views }}</small>
                    </div>
                  </li>
                </ul>
              </div>

              <ng-container *ngIf="currentUser.description">
                <div class="jobsearch-content-title">
                  <h2>Company Description</h2>
                </div>
                <div class="jobsearch-description">
                  {{ currentUser.description }}
                </div>
              </ng-container>
            </div>

            <div #reviewList class="dash-reviews-list jobsearch-employer-wrap-section">
              <div *ngIf="reviews?.length" class="jobsearch-content-title jobsearch-addmore-space">
                <h2>Company Reviews</h2>
              </div>

              <div class="jobsearch-company-review">
                <div *ngFor="let review of reviews" class="reviw-mainitem-con ">
                  <div #ratingDetails class="review-detail-popover">
                    <div class="rating-detail-item">
                      <span class="rating-title">Quality</span>
                      <div class="jobsearch-company-rating"><span class="jobsearch-company-rating-box"
                          [style.width.%]="review.rating_quality*20"></span>
                      </div>
                    </div>
                    <div class="rating-detail-item">
                      <span class="rating-title">Communication</span>
                      <div class="jobsearch-company-rating"><span class="jobsearch-company-rating-box"
                          [style.width.%]="review.rating_communication*20"></span>
                      </div>
                    </div>
                    <div class="rating-detail-item">
                      <span class="rating-title">Goodwill</span>
                      <div class="jobsearch-company-rating"><span class="jobsearch-company-rating-box"
                          [style.width.%]="review.rating_goodwill*20"></span>
                      </div>
                    </div>
                  </div>

                  <figure>
                    <a [routerLink]="['/user-details/', review.candidate_username]" class="company-review-thumb"><img
                        [src]="getUserProfilePicture(review.candidate_profile_photo)"></a>
                    <figcaption>
                      <div class="jobsearch-company-review-left">
                        <a [routerLink]="['/user-details/', review.candidate_username]"
                          class="reviewr-user-name">{{review.candidate_display_name}}</a>

                        <div class="jobsearch-company-rating" (mouseenter)="showDetailRating(ratingDetails)"
                          (mouseleave)="hideDetailRating(ratingDetails)">
                          <span class="jobsearch-company-rating-box" style="width: 93.333333333334%;"></span>
                        </div>

                        <small>{{review.rating_overall}}</small>
                      </div>

                      <time>{{review.created_at | date:"MMMM d, yyyy"}}</time>
                    </figcaption>
                  </figure>

                  <div class="reviw-contntholdr-con">
                    <div class="jobsearch-company-review-text" [innerHTML]="review.review">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div #addReviewForm class="jobsearch-employer-wrap-section jobsearch-margin-bottom">
              <div *ngIf="ifShowAddReviewForm()" id="add_review_form_sec">
                <div class="jobsearch-content-title jobsearch-addmore-space">
                  <h2>Leave Your Review</h2>
                </div>
                <div class="jobsearch-add-review-con">

                  <form [formGroup]="reviewForm" id="jobsearch-review-form" class="jobsearch-addreview-form"
                    method="post" (ngSubmit)="onReviewSubmit()">
                    <ul>
                      <li>
                        <div class="review-stars-sec">

                          <div class="review-stars-holder">
                            <label>Quality</label>
                            <div class="br-wrapper br-theme-fontawesome-stars">
                              <div class="br-widget">
                                <a *ngFor="let rating of [1, 2, 3, 4, 5]"
                                  [ngClass]="{'br-selected': rating <= reviewForm.value.rating_quality}"
                                  (click)="setRating(rating, 'rating_quality')"></a>
                              </div>
                            </div>
                          </div>

                          <div class="review-stars-holder">
                            <label>Communication</label>
                            <div class="br-wrapper br-theme-fontawesome-stars">
                              <div class="br-widget">
                                <a *ngFor="let rating of [1, 2, 3, 4, 5]"
                                  [ngClass]="{'br-selected': rating <= reviewForm.value.rating_communication}"
                                  (click)="setRating(rating, 'rating_communication')"></a>
                              </div>
                            </div>
                          </div>

                          <div class="review-stars-holder">
                            <label>Goodwill</label>
                            <div class="br-wrapper br-theme-fontawesome-stars">
                              <div class="br-widget">
                                <a *ngFor="let rating of [1, 2, 3, 4, 5]"
                                  [ngClass]="{'br-selected': rating <= reviewForm.value.rating_goodwill}"
                                  (click)="setRating(rating, 'rating_goodwill')"></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="review-overall-stars-sec">
                          <span class="rating-text">Overall Rating</span>
                          <span class="rating-num">{{reviewForm.value.rating_overall}}</span>
                          <div class="jobsearch-company-rating"><span class="jobsearch-company-rating-box"
                              [style.width.%]="reviewForm.value.rating_overall*20"></span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <textarea name="user_comment" placeholder="Your Review" formControlName="review"></textarea>
                      </li>
                      <li>
                        <input type="submit" id="jobsearch-review-submit-btn" value="Submit now"
                          [disabled]="!reviewForm.valid">
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>

            <!-- Active jobs from the employer -->
            <div *ngIf="activeJobs.length > 0" class="jobsearch-margin-top">
              <div class="jobsearch-section-title">
                <h2>Active Jobs From {{currentUser.display_name}}</h2>
              </div>

              <div
                class="jobsearch-job jobsearch-joblisting-classic jobsearch-jobdetail-joblisting jobsearch-empdetail-activejobs">
                <ul class="jobsearch-row">
                  <ng-container *ngFor="let job of activeJobs">
                    <li class="jobsearch-column-12">
                      <div class="jobsearch-joblisting-classic-wrap">
                        <figure>
                          <a [routerLink]="['/jobs/details/', job.slug]" class="">
                            <img
                              [src]="job.user_profile_photo ? helperService.getImageUrl(job.user_profile_photo, 'users', 'thumb') : 'assets/img/avatar-default.png'"
                              alt="">
                          </a>
                        </figure>
                        <div class="jobsearch-joblisting-text">
                          <div class="jobsearch-table-layer">
                            <div class="jobsearch-table-row">
                              <div class="jobsearch-table-cell">
                                <div class="jobsearch-list-option">
                                  <h2 class="jobsearch-pst-title " data-job-id="17505">
                                    <a [routerLink]="['/jobs/details/', job.slug]">
                                      {{job.title}} </a>
                                  </h2>
                                  <ul>
                                    <li class="job-company-name"><a [routerLink]="['/user-details', job.username]">@
                                        {{job.user_display_name}}</a></li>
                                    <li *ngIf="job.address">
                                      <i class="jobsearch-icon jobsearch-maps-and-flags"></i>{{job.address}}
                                    </li>
                                  </ul>
                                  <ul>
                                    <li><i class="jobsearch-icon jobsearch-filter-tool-black-shape"></i>
                                      <a [routerLink]="['/jobs']" [queryParams]="{sector: job.job_sector_id}" class=""
                                        [innerHTML]="job.job_sector"></a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="jobsearch-table-cell">
                                <div class="jobsearch-job-userlist">
                                  <a class="jobsearch-option-btn" [class]="job.job_type">
                                    {{getJobType(job)}} </a>
                                  <div class="like-btn ">
                                    <a (click)="updateFavoriteJob(job)"
                                      class="shortlist jobsearch-add-job-to-favourite jobsearch-job-like"
                                      [ngClass]="{'jobsearch-job-favorite': isFavoriteJob(job)}">
                                      <i class="jobsearch-icon jobsearch-heart"></i>
                                    </a>
                                    <span class="job-to-fav-msg-con"></span>
                                  </div>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>

                <div class="lodmore-jobs-btnsec">
                  <a *ngIf="activeJobs.length < activeJobsCount" (click)="getActiveJobs()"
                    class="lodmore-empactjobs-btn jobsearch-bgcolor">Load More</a>
                </div>
              </div>
            </div>
          </div>
          <!-- Job Detail Content -->

          <!-- Job Detail SideBar -->
          <aside class="jobsearch-column-4 jobsearch-typo-wrap">

            <div class="jobsearch_side_box jobsearch_box_contact_form">
              <div class="jobsearch-wdg-box-title">
                <h2>Contact Form</h2>
              </div>
              <form [formGroup]="contactEmployerForm" (ngSubmit)="onSubmitContactForm(employerContactForm)"
                #employerContactForm="ngForm">

                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name" placeholder="Name" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Email</mat-label>
                  <input required matInput formControlName="email" placeholder="Email" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Phone</mat-label>
                  <input matInput formControlName="phone" placeholder="Phone" />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Message</mat-label>
                  <textarea matInput formControlName="message" rows="5"></textarea>
                </mat-form-field>

                <button class="wil-btn wil-btn--primary wil-btn--block wil-btn--md wil-btn--round" type="submit"
                  [disabled]="!contactEmployerForm.valid || loading">
                  Send
                </button>

                <p *ngIf="showEmployerError" class="errorMessage">{{ errorMessageEmployer }}</p>

                <div class="loading-spinner" *ngIf="loading">
                  <i class="fa fa-spinner fa-spin"></i>
                </div>

                <p *ngIf="message" class="message">{{message}}</p>
              </form>
            </div>
            <div class="jobsearch_side_box jobsearch_box_map">
              <div class="jobsearch-map">
                <div id="googleMap" style="width:100%; height:300px;"></div>
              </div>
            </div>

          </aside>

        </div>
      </div>
    </div><!-- Main Section -->
  </div>
</div>
