<header id="wilcity-header-section" class="header_module__Snpib js-header-sticky" style="background-color: #048953">
  <div class="bd-header-area">
    <a class="bd-header-hamburger" (click)="showMobileMenu = !showMobileMenu">
      <mat-icon>menu</mat-icon>
    </a>
    <div class="wil-nav-mobile-wrapper" [ngClass]="{'show': showMobileMenu}">
      <nav class="wil-nav wil-nav-mobile">
        <ul class="nav-menu">
          <li class="menu-item" *ngFor="let item of menuItems">

            <mat-accordion *ngIf="item.children?.length; else singleMenu">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ item.title }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <ul class="dropdown-menu-mbl">
                    <li *ngFor="let child of item.children">
                      <a (click)="showMobileMenu = false" [routerLink]="[child.route]">{{ child.title }}</a>
                    </li>
                  </ul>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <ng-template #singleMenu>
              <a [routerLink]="[item.route]" routerLinkActive="mainmenu-link-active" (click)="showMobileMenu = false"
                style="color: rgb(255, 255, 255)">{{
                item.title
                }}</a>
            </ng-template>

          </li>
        </ul>
      </nav>

    </div>


    <div class="header_logo__2HmDH js-header-logo">
      <a [routerLink]="'home'" class="wilcity-site-logo">
        <img src="assets/img/BD-LOGO.png" alt="Nirectory" class="lazyloaded" data-ll-status="loaded" />
      </a>
    </div>

    <div class="mbl-profileMenu-area">
      <app-header-cart *ngIf="isCartVisible$ | async"></app-header-cart>
      <div *ngIf="isLoggedIn; else loggedOutMbl">
        <div id="wil-login-register-controller" class="header_login__1sQ6w header_login__mbl">
          <a [matMenuTriggerFor]="profilemenuMbl" class="header_loginHead__3HoVP">
            <img *ngIf="profileImage; else profimgLoggedout" [src]="profileImage" alt="" />
            <ng-template #profimgLoggedout>
              <mat-icon>account_circle</mat-icon>
            </ng-template>
          </a>
        </div>
        <mat-menu #profilemenuMbl="matMenu" class="cls-profilemenu-mbl">
          <nav class="wil-nav wil-nav-mobile wil-nav-destop">
            <ul class="nav-menu">
              <li class="menu-item" *ngFor="let item of profileMenus">
                <a *ngIf="item.callback; else routeLink" (click)="this[item.callback]()"
                  routerLinkActive="mainmenu-link-active" style="color: rgb(255, 255, 255)">{{ item.title }}</a>

                <ng-template #routeLink>
                  <a [routerLink]="[item.route]" routerLinkActive="mainmenu-link-active"
                    style="color: rgb(255, 255, 255)">{{ item.title }}</a>
                </ng-template>
              </li>
            </ul>
          </nav>
        </mat-menu>
      </div>

      <ng-template #loggedOutMbl>
        <a (click)="openLoginModal()" class="bd-header-account">
          <mat-icon>account_circle</mat-icon>
        </a>
      </ng-template>
    </div>
  </div>
</header>

<div class="bd-header-nav header_navWrapper__B2C9n">
  <div class="wil-tb">
    <div class="wil-tb__cell header-cell-left">
      <nav class="wil-nav">
        <ul id="wilcity-menu" class="nav-menu">
          <li class="menu-item" *ngFor="let item of menuItems">
            <a [routerLink]="[item.route]" routerLinkActive="mainmenu-link-active" style="color: rgb(255, 255, 255)">{{
              item.title
              }}</a>

            <ul *ngIf="item.children?.length" class="dropdown-menu" [class]="item.route">
              <li *ngFor="let child of item.children">
                <a [routerLink]="[child.route]">{{ child.title }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>

    <div class="wil-tb__cell header-cell-right">
      <div class="header_navWrapper__B2C9n">
        <div id="wil-login-register-controller" class="header_login__1sQ6w">
          <div class="header-button-group">
            <app-header-cart *ngIf="isCartVisible$ | async"></app-header-cart>
            <div *ngIf="isLoggedIn; else loggedOut">
              <div id="wil-login-register-controller" class="header_login__1sQ6w">
                <a [matMenuTriggerFor]="profilemenu" class="header_loginHead__3HoVP">
                  <img *ngIf="profileImage; else profimgLoggedout" [src]="profileImage" alt="" />
                  <ng-template #profimgLoggedout>
                    <mat-icon>account_circle</mat-icon>
                  </ng-template>
                </a>
              </div>
              <mat-menu #profilemenu="matMenu" class="cls-profilemenu-mbl">
                <nav class="wil-nav wil-nav-mobile wil-nav-destop">
                  <ul class="nav-menu">
                    <li class="menu-item" *ngFor="let item of profileMenus">
                      <a *ngIf="item.callback; else routeLink" (click)="this[item.callback]()"
                        routerLinkActive="mainmenu-link-active" style="color: rgb(255, 255, 255)">{{ item.title }}</a>

                      <ng-template #routeLink>
                        <a [routerLink]="[item.route]" routerLinkActive="mainmenu-link-active"
                          style="color: rgb(255, 255, 255)">{{ item.title }}</a>
                      </ng-template>
                    </li>
                  </ul>
                </nav>
              </mat-menu>
            </div>

            <ng-template #loggedOut>
              <button (click)="openLoginModal()" class="wil-btn no-text-sm wil-btn--primary2 wil-btn--round wil-btn--xs"
                id="wilcity-login-btn">
                <i class="la la-unlock-alt"></i> <span class="">Account</span>
              </button>
              <button (click)="openRegistrationModal()"
                class="wil-btn no-text-sm wil-btn--secondary wil-btn--round wil-btn--xs" id="wilcity-register-btn">
                <i class="la la-user-plus"></i> <span class="">Register</span>
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>