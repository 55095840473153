<table mat-table [dataSource]="dataSource">
  <!-- Id Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Order</th>
    <td mat-cell *matCellDef="let item">
      <a class="edit-url" [routerLink]="'/dashboard/order/' + item.id">#{{ item.id }}</a>
    </td>
  </ng-container>

  <!-- Date Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let item">
      {{ item.created_at | date: 'medium' }}
    </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let item">{{ item.status }}</td>
  </ng-container>

  <!-- Total Column -->
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef>Total</th>
    <td mat-cell *matCellDef="let item">{{ item.total | currency }}</td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let item">
      <a mat-flat-button color="primary" [routerLink]="'/dashboard/order/' + item.id">View</a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">No data matching.</td>
  </tr>
</table>
