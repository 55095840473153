<h1 class="confirmation-dialog-title" mat-dialog-title>Confirm!</h1>

<mat-dialog-content>
  <p>{{data.message}}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">No</button>
  <button mat-button [mat-dialog-close]="true">Yes</button>
</mat-dialog-actions>
